import { Injectable } from '@angular/core';
import { AssetsApiResponse } from './model/assetsapi.response';
@Injectable({
  providedIn: 'root'
})
export class AssetsDataService {
  public assets: AssetsApiResponse;
  //constructor(private user: GetAllUsersApiResponse) { }

}
