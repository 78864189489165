import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../helpers/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): boolean {
    if ( this.auth.isAuthenticated() ) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}

@Injectable()
export class AdminGuard implements CanActivate {
  constructor( private auth: AuthService, private router: Router ) {}

  canActivate(): boolean {
    console.log('IN ADMIN GUARD');
    // var userGroup = localStorage.getItem("userGroupID");
    // if (userGroup == "1") {
    //   return true;
    // } else {
    //   this.router.navigateByUrl("/unauthorized");
    //   return false;
    // }
    return false;
  }
}

@Injectable()
export class PartnerGuard implements CanActivate {
  constructor( private auth: AuthService, private router: Router ) {}

  canActivate(): boolean {
    console.log('IN PARTNER GUARD');
    // var userGroup = localStorage.getItem("userGroupID");
    // if (userGroup == "5") {
    //   return true;
    // } else {
    //   this.router.navigateByUrl("/unauthorized");
    //   return false;
    // }
    return false;
  }
}

@Injectable()
export class RegisterGaurd implements CanActivate {
  constructor( private auth: AuthService, private router: Router ) {}

  canActivate(): boolean {
    // if (this.auth.isAuthenticated()) {
    //   this.router.navigateByUrl("/packages");
    //   return false;
    // } else {
    //   return true;
    // }
    return false;
  }
}
