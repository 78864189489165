import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ApiService } from "../core/api.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { UserdataService } from '../userdata.service';
import { Observable } from "rxjs/index";
import { GetAllRolesApiResponse } from '../model/getAllRolesApiResponse.response';
import { GetAllRolePolicyResponse } from '../model/getAllRolePoliciesResponse.response';
export interface Fruit {
  name: string;
}
export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-update-register-user',
  templateUrl: './update-register-user.component.html',
  styleUrls: ['./update-register-user.component.css']
})
export class UpdateRegisterUserComponent implements OnInit {

  loginForm: FormGroup;
  roles: GetAllRolesApiResponse[];
  //toppings = new FormControl();
  //toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  selectedRole: string; //roleId
  selectedPolicy: string; //
  isLoading = true;

  //rolePolicies: []; 
  i: number;
  x: any;

  selected = null;
  private roleToPoliciesMap = new Map<string, string[]>();
  private roleIdToRoleNameMap = new Map<string, string>();


  constructor(private router: Router, private apiService: ApiService, private formBuilder: FormBuilder, private userDataService: UserdataService) { }
  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      firstname: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      lastname: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      username: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobilenumber: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      countrycode: ['0', Validators.compose([Validators.required])],
    });
    console.log("Received user with user name:" + this.userDataService.user.firstName);
    console.log("Received user with user name:" + this.userDataService.user.userName);
    this.loginForm.patchValue({
      'username': this.userDataService.user.userName, 'firstname': this.userDataService.user.firstName,
      'lastname': this.userDataService.user.lastName, 'email': this.userDataService.user.email, 'mobilenumber': this.userDataService.user.phoneNumber
    });

    this.loginForm.controls['countrycode'].disable();
  }
  onRegisterClick() {
    console.log("Register User Button Clicked")
    this.router.navigate(['users']);

  }

  onUpdateUserRegistration() {
    if (this.loginForm.invalid) {
      return;
    }
    const updateUserPayload = {
      FirstName: this.loginForm.controls.firstname.value,
      LastName: this.loginForm.controls.lastname.value,
      UserName: this.loginForm.controls.username.value,
      Email: this.loginForm.controls.email.value,
      MobileNumber: this.loginForm.controls.mobilenumber.value,

      isActive: true
    }

    this.apiService.updateUser(updateUserPayload)
      .subscribe(
        data => {
          console.log(data);

          alert("Congrats!" + " " + "Your credentials are Updated Successfully");
          this.router.navigate(['users']);
        },
        error => {
          alert(error);
        }

      );
  }
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [
    { name: 'Super Admin' },
    { name: 'IT Admin' },
    { name: 'User' },
  ];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  onClickOfBack() {
    //Go Back to Users Table
    this.router.navigate(['users']);
  }
  onClickOfCheckUpdateUser() {
    // Call Update User API
  }

  getRoles() {

    this.apiService.getAllRoles().subscribe(data => {
      console.log("Display Data" + data)
      console.log("Roles Name " + data[0].name);
      console.log("Roles Policy of Data[0] " + data[0].rolePolicies[0].name);
      console.log("Roles Policy of Data[0] " + data[0].rolePolicies[1].name);
      console.log("Roles Policy of Data[0] " + data[0].rolePolicies[2].name);
      this.roles = data as GetAllRolesApiResponse[];
      console.log("Now data in roles Object", this.roles);
      console.log("rolePolicies ", this.roles[0].rolePolicies);
      //console.log(this.roles[this.i].name);
      // console.log(this.roles[this.i].roleId);
      // console.log(this.roles[this.i].rolePolicies[this.i].name);

      for (this.i = 0; this.i < this.roles[this.i].rolePolicies.length; this.i++) {
        this.x += this.roles[this.i].rolePolicies[this.i].name;
        console.log("*****", this.x);
      }

      var i: number = 0;
      for (i = 0; i < this.roles.length; i++) {
        var roleName = this.roles[i].name;
        var roleId = this.roles[i].roleId;
        var policies: string[] = [];
        var j: number = 0;
        for (j = 0; j < this.roles[i].rolePolicies.length; j++) {
          policies.push(this.roles[i].rolePolicies[j].name);
        }
        this.roleIdToRoleNameMap.set(roleId, roleName);
        this.roleToPoliciesMap.set(roleId, policies);
      }
    })
  }

  get userroles(): [string, string][] {
    return Array.from(this.roleIdToRoleNameMap.entries());
  }

  get rolepolicies(): string[] {
    return this.roleToPoliciesMap.get(this.selectedRole);
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
}
