import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../core/api.service";
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  
  loginForm: FormGroup;
 // model: any = {};
  invalidRegistration: boolean = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private dialogService: DialogService) { }

  onRegister() {
    if (this.loginForm.invalid) {
      return;
    }
    const registerClientAdminUserPayload = {
      FirstName: this.loginForm.controls.firstname.value,
      LastName: this.loginForm.controls.lastname.value,
      UserName: this.loginForm.controls.username.value,
      Email:this.loginForm.controls.email.value,
      Password:this.loginForm.controls.password.value,
      ClientName:this.loginForm.controls.clientname.value,
      MobileNumber: this.loginForm.controls.mobilenumber.value,
     // CountryCode:this.loginForm.controls.countrycode.value
    }
    this.apiService.registerClientAdminUser(registerClientAdminUserPayload)
      .subscribe(
        data => {
      
          
             // console.log(data.jwTtoken);
              //window.localStorage.setItem('token', data.result.token);
              //localStorage.setItem('token', data.result.token); - there is nothing called data.result.
              //localStorage.setItem('token', data.jwTtoken);
              //alert(this.registerForm.controls.clientname.value + " " + data.statusMessage);
              console.log(data);
              //this.router.navigate(['assets']);
              //console.log(data.isJWTtokenCreated)
             // console.log(data.statusMessage)
              //alert(this.loginForm.controls.clientname.value + " " + data.statusMessage);
             // alert("Congrats!" + " " + "You are Registered Successfully");
              //this.router.navigate(['login']);

             var result = confirm("Congrats!" + " " + "You are Registered Successfully");
              if (result) {
            //Logic to delete the item
                
                this.router.navigate(['login']);
                return true;
              }else {
                this.router.navigate(['/register']);
                return false;
              }
              

        
          

      },
            error => {
              alert("Please Enter Valid Credentials! This user may already be in the Database.");
             // this.error = error
            }

    );
  }

ngOnInit() {
    window.localStorage.removeItem('token');
    this.loginForm = this.formBuilder.group({
      firstname: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      lastname: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      username: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      email: ['', Validators.compose([Validators.required,Validators.email])],
      password: ['', Validators.required, Validators.minLength(6)],
      clientname: ['Numino', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      mobilenumber: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      countrycode: ['0', Validators.compose([Validators.required])],
      
    });
    this.loginForm.controls['clientname'].disable();
    this.loginForm.controls['countrycode'].disable();
  }
/*
  openRegisterConfirmDialog(){
    console.log("on click of dialog button");
    this.dialogService.openConfirmDialog("Congrats!" + " " + "You are Registered Successfully").afterClosed().subscribe(res => {
      console.log(res);
      if(res){
        this.router.navigate(['login']);
        return true;
      }else{
        this.router.navigate(['/register']);
        return false;
      }
    });
  }*/


  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
        event.preventDefault();

    }
}

}
