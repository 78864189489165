import { Directive, Input, TemplateRef, ViewRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';
@Directive({selector: '[ifRole]'})
export class RoleDirective {
    
    constructor(private templateRef: TemplateRef<any>, 
                private viewContainer: ViewContainerRef, 
                private authService: AuthService){}
    
    roleName: string;

    @Input() set ifRole(roleName: string) {
        this.roleName = roleName;

        /*
        switch(roleName) {
            case "CreateSettings" :
             if (userPermissions.createSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);     
             }
             break;
            case "AssignSettings" :
            if (userPermissions.assignSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "UpdateSettings":
            if (userPermissions.updateSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "ReadSettings" :
            if (userPermissions.readSettings {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "DeleteSettings" : 
            if (userPermissions.deleteSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            
        }
        
        switch(roleName) {
            case "CreateEmployees" :
             if (userPermissions.createEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);     
             }
             break;
            case "AssignEmployees" :
            if (userPermissions.assignEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "UpdateEmployees":
            if (userPermissions.updateEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "ReadEmployees" :
            if (userPermissions.readEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "DeleteEmployees" : 
            if (userPermissions.deleteEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            
        }
        
        */

    }

    ngOnInit() {
        const userPermissions =  this.authService.getUserPermissions();
        
        switch(this.roleName) {
            // For Assets
            case "CreateAsset" :
             if (userPermissions.createAsset) {
                this.viewContainer.createEmbeddedView(this.templateRef);     
             }
             break;
            case "AssignAsset" :
            if (userPermissions.assignAsset) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "UpdateAsset":
            if (userPermissions.updateAsset) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "ReadAsset" :
            if (userPermissions.readAsset) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "DeleteAsset" : 
            if (userPermissions.deleteAsset) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;  
            //For Settings
            case "CreateSettings" :
             if (userPermissions.createSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);     
             }
             break;
            case "AssignSettings" :
            if (userPermissions.assignSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "UpdateSettings":
            if (userPermissions.updateSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "ReadSettings" :
            if (userPermissions.readSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "DeleteSettings" : 
            if (userPermissions.deleteSettings) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            //For Employees
            case "CreateEmployees" :
             if (userPermissions.createEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);     
             }
             break;
            case "AssignEmployees" :
            if (userPermissions.assignEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "UpdateEmployees":
            if (userPermissions.updateEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "ReadEmployees" :
            if (userPermissions.readEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
            case "DeleteEmployees" : 
            if (userPermissions.deleteEmployees) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            break;
        }
    }
}