import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {NavItem} from '../model/nav-item';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../core/api.service";

import { LogoutUser} from '../model/logoutuser.model';
import { DialogService } from '../services/dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { AssetsApiResponse } from '../model/assetsapi.response';
import { assertDataInRange } from '@angular/core/src/render3/state';
import { filter } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-mat-dialog',
  templateUrl: './mat-dialog.component.html',
  styleUrls: ['./mat-dialog.component.css']
})
export class MatDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, private router: Router, private assetListService: ApiService, private apiService: ApiService,
  public dialogRef: MatDialogRef<MatDialogComponent>){}

  dataSource : any;
  //dataSourceEmpty = [];
  isLoading = true;
  assets: AssetsApiResponse[];
  displayedColumns = ['id','assetSubTypeId','name', 'serialNo', 'model', 'vendor','cost','invoiceNo', 'Actions'];

 
  ngOnInit() {
/* console.log('DATA I',this.data.assetid);
   console.log("all records..........."+this.assets); */
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  closeDialog(){    
    this.dialogRef.close(false);
  }

  }
    



  


