import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from '../error-handler.service';
import { CognitoUtil, StorageUtil } from '../utils/index';

@Injectable()
export class LoginService {

    constructor( private http: HttpClient,
        private cognitoUtil: CognitoUtil,
        private storageUtil: StorageUtil,
        private httpErrorHandler: HttpErrorHandler,
        private router: Router ) { }


    authenticateUser( userName, password ) {
        const authenticationData = {
            Username: userName,
            Password: password
        };

        this.router.navigateByUrl('/home')

        // Authenticate the application using cognito
        // this.cognitoUtil.authenticateUser( authenticationData ).then(( token ) => {
        //     this.storageUtil.set( 'token', token );
        // }).catch(( error ) => {
        //     this.httpErrorHandler.handleError( error );
        // });
    }

}
