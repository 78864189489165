import { Component } from '@angular/core';

import { S3Util, StorageUtil } from './utils/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

    constructor( private s3util: S3Util, private storageUtil: StorageUtil ) { }

}
