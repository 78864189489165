import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import {User} from "../model/user.model";
import {Observable} from "rxjs/index";
import {LoginApiResponse} from "../model/loginapi.response";
import { SignUpUser } from '../model/signUpUser.model';
//import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
//import { Observable, throwError } from 'rxjs';
import {SignupApiResponse} from "../model/signupapi.response";
import { LogoutUserApiResponse } from "../model/logoutuser.response";
import { GetAllUsersApiResponse } from '../model/getallusersapi.response';
import { UpdateUser} from '../model/update-user.model';
import { LogoutUser } from '../model/logoutuser.model';
import { GetAllRolesApiResponse } from '../model/getAllRolesApiResponse.response';
import { GetAllRolePolicyResponse } from '../model/getAllRolePoliciesResponse.response';
import { RegisterClientAdminUser } from '../model/registerClientAdminUser.model';
import { AssetTypeApiResponse } from '../model/assetTypeapi.response';
import { AssetsApiResponse } from '../model/assetsapi.response';
import { AssetAssignment } from '../model/assetAssignment.model';
import { NumberAttributeValue } from 'aws-sdk/clients/clouddirectory';
import { AssetSubType } from '../model/assetSubType.model';

@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }
  
// User Management Production APIs
 baseUrl: string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/AccountBusiness/Login';
 registerUrl: string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/AccountBusiness/registerClientUser';
 logoutUrl:string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/AccountBusiness/Logout';
 getAllUsersUrl:string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/user';
 updateUserUrl:string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/user';
 getAllRolesUrl:string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/access/role';
 registerClientAdminUserUrl:string = 'https://rlr0r7zig8.execute-api.us-west-2.amazonaws.com/Prod/api/AccountBusiness/registerClientAdminUser';

// Asset Management Production APIs

assetTypesUrl: string = 'https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/assetType';
assetsUrl: string = 'https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset';
createNewAssetUrl: string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset";
viewAssetDetails:string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset";
updateCreatedAssetUrl: string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset";
deleteAssetUrl:string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset";
getAssetByIdUrl:string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset";
assignAssetToUserUrl:string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/asset"; 
assetSubTypeUrl:string = "https://qmpii5pgxi.execute-api.us-west-2.amazonaws.com/Prod/api/assetSubType";


  // User Management Methods :
  login(loginPayload) : Observable<LoginApiResponse> {
    return this.http.post<LoginApiResponse>(this.baseUrl, loginPayload);
  }

  registerUser(registerPayload): Observable<SignupApiResponse>{
    return this.http.post<SignupApiResponse>(this.registerUrl, registerPayload);
  }

  registerClientAdminUser(registerClientAdminUserPayload): Observable<RegisterClientAdminUser>{
    return this.http.post<RegisterClientAdminUser>(this.registerClientAdminUserUrl, registerClientAdminUserPayload);
  }

  logoutUser(user): Observable<Response>{
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (localStorage.getItem('token'))
   });
    return this.http.post<Response>(this.logoutUrl, user, { headers: reqHeader });
  }


  getAllUsers(): Observable<GetAllUsersApiResponse[]>{
    
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (localStorage.getItem('token'))
   });
       return this.http.get<GetAllUsersApiResponse[]>(this.getAllUsersUrl, { headers: reqHeader });
    
  }
  
  updateUser(updateUserPayload): Observable<UpdateUser>{
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (localStorage.getItem('token'))
   });
    return this.http.put<UpdateUser>(this.updateUserUrl, updateUserPayload, { headers: reqHeader });
  }

  getAllRoles(): Observable<GetAllRolesApiResponse[]>{
    
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (localStorage.getItem('token'))
   });
       return this.http.get<GetAllRolesApiResponse[]>(this.getAllRolesUrl, { headers: reqHeader });
    
  }

  getAllRolePolicies(): Observable<GetAllRolePolicyResponse[]>{
    
    var reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (localStorage.getItem('token'))
   });
       return this.http.get<GetAllRolePolicyResponse[]>(this.getAllRolesUrl, { headers: reqHeader });
    
  }

  // Asset Management Methods :

  
    getAllAssetTypes(): Observable<AssetTypeApiResponse[]>{
    
      var reqHeader = new HttpHeaders({ 
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
         return this.http.get<AssetTypeApiResponse[]>(this.assetTypesUrl, { headers: reqHeader });
      
    }


    getAllAssets(): Observable<AssetsApiResponse[]>{
    
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
         return this.http.get<AssetsApiResponse[]>(this.assetsUrl, { headers: reqHeader });
      
    }

    getAllAssetSubTypes(): Observable<AssetSubType[]>{
    
      var reqHeader = new HttpHeaders({ 
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
         return this.http.get<AssetSubType[]>(this.assetSubTypeUrl, { headers: reqHeader });
      
    }

    getAssetById(id: number) {
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
      return this.http.get<AssetsApiResponse>(this.getAssetByIdUrl + '/' + id, { headers: reqHeader });
    }

    createNewAsset(createNewAssetPayload: AssetsApiResponse): Observable<AssetsApiResponse>{
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
      return this.http.post<AssetsApiResponse>(this.assetsUrl, createNewAssetPayload, { headers: reqHeader });
    }
    
  
    updateCreatedAsset(updateCreatedAsset:AssetsApiResponse){
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
      return this.http.put(this.updateCreatedAssetUrl + '/' + updateCreatedAsset.id,updateCreatedAsset, { headers: reqHeader, responseType: 'text' });
    }
    

    deleteAsset(id: number){
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
      //return this.http.delete<AssetsApiResponse[]>(this.deleteAssetUrl + '/' + id, { headers: reqHeader });
      return this.http.delete(this.deleteAssetUrl + '/' + id, { headers: reqHeader, responseType: 'text' });
    }

    assignAssetToUser(assetId:number, assetAssignment: AssetAssignment):Observable<Response>{
      var reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (localStorage.getItem('token'))
     });
      return this.http.post<Response>(this.assignAssetToUserUrl + '/' + assetId + '/' + 'assign', assetAssignment,{ headers: reqHeader });
    }
    
  // Error Handling

  private handleError(errorResponse: HttpErrorResponse){
        if(errorResponse.error instanceof ErrorEvent){
            console.error('Client Side Error :', errorResponse.error.message);
        }else {
            console.error('Server Side Error :', errorResponse);
        }

        //return new ErrorObservable('There is a problem with the service, we are notified and working on it.')
  }


}