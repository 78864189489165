export class UserPermissions {
    //asset related permissions
    createAsset: boolean = false;
    readAsset: boolean = false;
    updateAsset: boolean = false;
    assignAsset: boolean = false;
    deleteAsset: boolean = false;

    //settings related permissions
    updateSettings: boolean = false;
    deleteSettings: boolean = false;
    assignSettings: boolean = false;
    createSettings: boolean = false;
    readSettings: boolean = false;

    //employees related permissions
    createEmployees: boolean = false;
    readEmployees: boolean = false;
    updateEmployees: boolean = false;
    deleteEmployees: boolean = false;
    assignEmployees: boolean = false;
}