import { Injectable } from '@angular/core';
import {GetAllUsersApiResponse} from './model/getallusersapi.response';
@Injectable({
  providedIn: 'root'
})
export class UserdataService {
  public user: GetAllUsersApiResponse;
  //constructor(private user: GetAllUsersApiResponse) { }

}
