import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiService } from "../core/api.service";
import { GetAllUsersApiResponse } from '../model/getallusersapi.response';
import { JwttokenDataService } from '../jwttoken-data.service';
//import { assetStatus } from '../model/assetStatus.json';
import { AssetSubType } from '../model/assetSubType.model';
import { AuthService } from '../helpers/auth.service';
import { SnackBarService } from '/home/user/asset-management/frontend/src/app/services/snack-bar.service'
import { AssetsApiResponse } from '../model/assetsapi.response';
import { AssetTypeApiResponse } from '../model/assetTypeapi.response';

export interface AssetStatus {
  id: string;
  status: string;
}

@Component({
  selector: 'app-view-hardware-assets',
  templateUrl: './view-hardware-assets.component.html',
  styleUrls: ['./view-hardware-assets.component.css']
})
export class ViewHardwareAssetsComponent implements OnInit {

  viewAssetForm: FormGroup;
  assignAssetForm: FormGroup;

  users: GetAllUsersApiResponse[];
  selectedUsername = 'a';


  asset_attribute_details = [];
  asset_assignment_details = [];
  loginUserId;
  assetId = localStorage.getItem("editAssetId");
  selectedUserId = localStorage.getItem("editUserId");
  loggedInUserId = this.jwttokenservice.getJWTTokenDetails();
  selectedAssetStatus: string;
  selectedUser: string;
  selectedAssetSubType: string = "1";
  assetSubTypes: AssetSubType[];
  assetSubTypeName;
  hideElement: boolean = true;
  isLoading = true;
  minDate = new Date(2019, 0, 1);
  maxDate = new Date(2039, 0, 1);
  isDoneButtonVisible = false;
  isAssignButtonVisible = false;
  isEditButtonVisible = true;
  isBackButtonVisible = true;
  makeName;
  assetName;
  assetType;
  assets: AssetsApiResponse[];
  private assetSubTypeMap = new Map<any, string>();
  private assetTypeMap = new Map<any, string>();

  constructor(private router: Router, private apiService: ApiService, private formBuilder: FormBuilder, private jwttokenservice: JwttokenDataService, private authService: AuthService, private snackbar: SnackBarService) { }

  ngOnInit() {
    var userPermissions = this.authService.getUserPermissions();
    this.isDoneButtonVisible = userPermissions.updateAsset;
    this.isAssignButtonVisible = userPermissions.assignAsset;
    this.isEditButtonVisible = userPermissions.updateAsset;
    console.log("Ng on init called", userPermissions);
    this.loginUserId = this.jwttokenservice.getJWTTokenDetails();

    this.viewAssetForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.compose([Validators.required])],
      serialNo: ['', Validators.compose([Validators.required])],
      make: ['', Validators.compose([Validators.required])],
      model: ['', Validators.compose([Validators.required])],
      vendor: ['', Validators.compose([Validators.required])],
      cost: ['', Validators.compose([Validators.required])],
      purchasedDate: ['', Validators.compose([Validators.required])],
      warrentyDate: ['', Validators.compose([Validators.required])],
      serviceTag: ['', Validators.compose([Validators.required])],
      invoiceNo: ['', Validators.compose([Validators.required])],
      insuredDate: ['', Validators.compose([Validators.required])],
      insuredEndDate: ['', Validators.compose([Validators.required])],
      remark: ['', Validators.compose([Validators.required])],
      assetSubTypeId: ['1', Validators.compose([Validators.required])],
      assetAttributeDetails: this.asset_attribute_details,
      assetAssignments: this.asset_assignment_details,



    });

    this.assignAssetForm = this.formBuilder.group({

      statusId: ['', Validators.compose([Validators.required])],
      assignedByUserId: [this.loggedInUserId, Validators.compose([Validators.required])],
      assignedToUserId: ['', Validators.compose([Validators.required])],
      assignedStartDate: ['', Validators.compose([Validators.required])],
      assignedEndDate: ['', Validators.compose([Validators.required])],
    });


    console.log("Getting asset subtypes")
    this.isLoading = true;
    this.apiService.getAllAssetSubTypes()
      .subscribe(data => {
        this.isLoading = false;
        this.isDoneButtonVisible = false;
        this.assetSubTypes = data;
        this.selectedAssetSubType = '1';
        console.log("Asset Sub Types", this.assetSubTypes)
        for (var i = 0; i < this.assetSubTypes.length; i++) {
          var id = this.assetSubTypes[i].id;
          var name = this.assetSubTypes[i].name;
          this.assetSubTypeMap.set(id, name);
        }
      });

    console.log("Getting asset by id");
    this.isLoading = true;
    this.apiService.getAssetById(+this.assetId)
      .subscribe(data => {
        this.isLoading = false;
        this.isDoneButtonVisible = false;
        this.viewAssetForm.disable();

        this.makeName = data.make;
        this.assetName = data.name;
        var viewAssets = data;
        this.assetSubTypeName = this.assetSubTypeMap.get(viewAssets.assetSubTypeId);
        console.log('ASSET SUB TYPE', this.assetSubTypeName);
        this.viewAssetForm.patchValue({
          'assetSubTypeId': viewAssets.assetSubTypeId, 'name': viewAssets.name, 'serialNo': viewAssets.serialNo,
          'make': viewAssets.make, 'model': viewAssets.model, 'serviceTag': viewAssets.serviceTag, 'vendor': viewAssets.vendor, 'invoiceNo': viewAssets.invoiceNo, 'cost': viewAssets.cost, 'remark': viewAssets.remark, 'purchasedDate': viewAssets.purchasedDate,
          'warrentyDate': viewAssets.warrentyDate, 'insuredDate': viewAssets.insuredDate, 'insuredEndDate': viewAssets.insuredEndDate,
        });
        this.isLoading = true;
        this.apiService.getAllUsers().subscribe(data => {
          this.isLoading = false;
          this.assignAssetForm.disable();
          this.users = data;

        });



        if (userPermissions.assignAsset) {
          if (data.assetAssignments.length > 0) {

            var assignment = data.assetAssignments[0];

            this.assignAssetForm.patchValue({ 'assignedToUserId': assignment.assignedToUserId, 'statusId': '' + assignment.statusId, 'assignedStartDate': assignment.assignedStartDate, 'assignedEndDate': assignment.assignedEndDate });
          } else {

          }
        }


      });

    this.viewAssetForm.get('assetSubTypeId').setValue(this.selectedAssetSubType);
  }
  onClickOfBack() {

    this.router.navigate(['assets']);
  }
  onClickOfEditHardwareAssets() {

    this.isEditButtonVisible = false;
    this.isBackButtonVisible = true;
    this.viewAssetForm.enable();
    this.assignAssetForm.enable();

    this.isDoneButtonVisible = true;

  }
  onClickOfUpdateAssets() {
    if (this.viewAssetForm.get("purchasedDate").value > this.viewAssetForm.get("warrentyDate").value) {
      alert("Purchase Date cannot be greater than the Warranty Date");
      return;
    }
    if (this.viewAssetForm.get("insuredDate").value > this.viewAssetForm.get("insuredEndDate").value) {
      alert("Insured Start Date cannot be greater than the Insured End Date");
      return;
    }
    if (this.viewAssetForm.get("purchasedDate").value > this.viewAssetForm.get("insuredDate").value) {
      alert("Purchase Date cannot be greater than the Insured Start Date");
      return;
    }

    this.isLoading = true;
    var requestData = this.viewAssetForm.value;
    requestData.id = this.assetId;
    this.apiService.updateCreatedAsset(requestData)
      .subscribe(
        data => {

          this.isLoading = false;

          this.snackbar.openSnackBar("Successfuly Updated the Assets");
          this.router.navigate(['assets']);
        },
        error => {
          alert(error);
        });
  }

  onClickOfAssign() {
    console.log("IN ASSIGNED =====================>");

    if (this.assignAssetForm.get("assignedStartDate").value > this.assignAssetForm.get("assignedEndDate").value) {
      alert("Assigned Start Date cannot be greater than the Assigned End Date");
      return;
    }

    console.log("Logged in user :", this.loggedInUserId);
    console.log("AssetId:", this.assetId);
    console.log("Assigned user:", this.selectedUser);
    console.log("Selected asset status: ", this.selectedAssetStatus);

    let assetId = localStorage.getItem("editAssetId");
    this.apiService.assignAssetToUser(+this.assetId, this.assignAssetForm.value)
      .subscribe(
        data => {
          this.assignAssetForm.disable();
          this.snackbar.openSnackBar("This Asset is Assigned Successfully!");
          console.log("Assign Asset Data", data);
          this.asset_assignment_details.push(this.assignAssetForm.value);
        },
        error => {
          alert(error);
        });
  }

  assetsStatuses: AssetStatus[] = [
    { id: '1', status: 'Deallocated' },
    { id: '2', status: 'Allocated' }

  ];
}

