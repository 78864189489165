import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { ApiService } from "../core/api.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';
import { GetAllRolesApiResponse } from '../model/getAllRolesApiResponse.response';
// import { MustMatch } from '/home/user/asset-management/frontend/src/app/helpers/validators'
import { ifError } from 'assert';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterUserComponent implements OnInit {
  loginForm: FormGroup;
  selectedRole: string;
  selectedPolicy: string[];
  roles: GetAllRolesApiResponse[];
  toppings = new FormControl();
  i: number;
  x: any;
  selected = null;
  private roleToPoliciesMap = new Map<string, string[]>();
  private roleIdToRoleNameMap = new Map<string, string>();
  userroles: [string, string][];
  noPoliciesSelected = true;

  constructor(private router: Router, private apiService: ApiService, private formBuilder: FormBuilder) { }
  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      firstname: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      lastname: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      username: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      clientname: ['Numino', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      mobilenumber: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      countrycode: ['0'],
      confirmPassword: ['', [Validators.required]],
    },
      {
        validator: this.mustMatch
      }

    );
    this.loginForm.controls['clientname'].disable();
    this.loginForm.controls['countrycode'].disable();
    this.getRoles();
  }

  confirmPassword() {

    if (this.loginForm.get('password').value != this.loginForm.get('confirmPassword').value) {
      alert('enter valid password');
    }
  }
  onRegisterClick() {
    console.log("Register User Button Clicked")
    this.router.navigate(['users']);

  }

  checkPoliciesSelected() {
    return this.selectedPolicy.length > 0;
  }

  onClickOfCheckRegisterNewUser() {

    console.log("FORM================================>", this.loginForm);

    console.log("Selected role id : " + this.selectedRole)
    console.log("Length of selected policies : " + this.selectedPolicy.length);
    var i: number = 0;

    var rolePolicyDetail = { RoleId: this.selectedRole, Name: this.roleIdToRoleNameMap.get(this.selectedRole), rolePolicies: [] };

    var rolePolicyDetails = [];

    for (i = 0; i < this.selectedPolicy.length; i++) {
      console.log("Selected policy at index : " + i + " = " + this.selectedPolicy[i]);
      /*
      var selectedPolicy = new SelectedPolicy();
      selectedPolicy.name = this.selectedPolicy[i];
      userSelectedRole.rolePolicies.push(selectedPolicy);
      */
      rolePolicyDetail.rolePolicies.push({
        name: this.selectedPolicy[i]
      });
    }

    console.log("Policy Payload data to be sent");



    rolePolicyDetails.push(rolePolicyDetail);
    const registerPayload = {
      FirstName: this.loginForm.controls.firstname.value,
      LastName: this.loginForm.controls.lastname.value,
      UserName: this.loginForm.controls.username.value,
      Email: this.loginForm.controls.email.value,
      Password: this.loginForm.controls.password.value,
      ClientName: this.loginForm.controls.clientname.value,
      MobileNumber: this.loginForm.controls.mobilenumber.value,
      CountryCode: "0",//this.loginForm.controls.countrycode.value,
      RolePoliciesDetails: rolePolicyDetails

    };
    console.log("Register Request Sent", registerPayload);
    this.apiService.registerUser(registerPayload)
      .subscribe(
        data => {
          console.log("Registered New User Data With API Call", data);
          console.log(data.statusMessage)
          //alert(this.loginForm.controls.clientname.value + "and" +this.loginForm.controls.username.value + data.statusMessage);
          alert("Congrats!" + " " + "You are Registered Successfully");
          this.router.navigate(['users']);
        },
        error => {
          alert(error);
          this.router.navigate(['users']);
          // this.error = error
        }

      );
  }
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;



    // Reset the input value
    if (input) {
      input.value = '';
    }
  }


  onClickOfBack() {
    //Go Back to Users Table
    this.router.navigate(['users']);
  }
  getAllRoles() {

    this.apiService.getAllRoles()
      .subscribe(data => {
        console.log(data);
      },
        error => {
          alert(error);
          // this.error = error
        }
      );
  }

  getRoles() {

    this.apiService.getAllRoles().subscribe(data => {
      console.log("Display Data" + data)
      console.log("Roles Name " + data[0].name);
      console.log("Roles Policy of Data[0] " + data[0].rolePolicies[0].name);
      console.log("Roles Policy of Data[0] " + data[0].rolePolicies[1].name);
      console.log("Roles Policy of Data[0] " + data[0].rolePolicies[2].name);
      this.roles = data as GetAllRolesApiResponse[];
      console.log("Now data in roles Object", this.roles);
      console.log("rolePolicies ", this.roles[0].rolePolicies);
      //console.log(this.roles[this.i].name);
      // console.log(this.roles[this.i].roleId);
      // console.log(this.roles[this.i].rolePolicies[this.i].name);

      for (this.i = 0; this.i < this.roles[this.i].rolePolicies.length; this.i++) {
        this.x += this.roles[this.i].rolePolicies[this.i].name;
        console.log("*****", this.x);
      }

      var i: number = 0;
      for (i = 0; i < this.roles.length; i++) {
        var roleName = this.roles[i].name;
        var roleId = this.roles[i].roleId;
        var policies: string[] = [];
        var j: number = 0;
        console.log("obtained role " + roleName)
        for (j = 0; j < this.roles[i].rolePolicies.length; j++) {
          policies.push(this.roles[i].rolePolicies[j].name);
          console.log(" -- policy name " + this.roles[i].rolePolicies[j].name)
        }
        this.roleIdToRoleNameMap.set(roleId, roleName);
        this.roleToPoliciesMap.set(roleId, policies);
      }
      this.userroles = Array.from(this.roleIdToRoleNameMap.entries());
      console.log("completed role policy fetch");
    })
  }
  get rolepolicies(): string[] {
    console.log("***Providing role policies")
    return this.roleToPoliciesMap.get(this.selectedRole);
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }

  onPolicyChange(event) {
    this.selectedPolicy.length > 0 ? this.noPoliciesSelected = false : this.noPoliciesSelected = true;
  }

  onRoleChange(event) {
    this.selectedPolicy = [];
    this.noPoliciesSelected = true
  }
  public mustMatch = (form: FormGroup) => {
    if (form.controls.password.value === form.controls.confirmPassword.value) {
      return null;
    } else {
      form.controls.confirmPassword.setErrors({ mustMatch: true });
    }
  }


}
