
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {UserPermissions} from '../model/permissions.model'
// import { AuthGuard, AdminGuard } from '../helpers/auth.guard';


@Injectable()
export class AuthService {

  constructor( public jwtHelper: JwtHelperService ) {}

  public getAuthorizationToken(): String {
    return this.jwtHelper.tokenGetter();
  }

  public isAuthenticated(): boolean {

    const token: string = this.jwtHelper.tokenGetter();

    if (!token) {
      return false;
    }

    const tokenExpired: boolean = this.jwtHelper.isTokenExpired(token);

    const decodeToken =  this.jwtHelper.decodeToken(token);
    console.log("Decoded token : " + this.jwtHelper.decodeToken(token));

    return !tokenExpired;
  }

  public getUserPermissions(): UserPermissions {
    const token: string = this.jwtHelper.tokenGetter();
    const decodeToken = this.jwtHelper.decodeToken(token);
    const permissions = JSON.parse(decodeToken.Permissions);
    const userPermissions =  new UserPermissions();
    //For Assets
    if ('Assets' in permissions) {
      var assetKeys = Object.keys(permissions.Assets);
      for (var key in assetKeys) {
        switch(assetKeys[key]) {
          case "Create" :
          userPermissions.createAsset = permissions.Assets.Create;
          break;
          case "Read" :
          userPermissions.readAsset = permissions.Assets.Read;
          break;
          case "Update" : 
          userPermissions.updateAsset = permissions.Assets.Update;
          break;
          case "Delete" :
          userPermissions.deleteAsset = permissions.Assets.Delete;
          break;
          case "Assign" :
          userPermissions.assignAsset = permissions.Assets.Assign;
          break;
        }
      }
    }
    // For Settings
    if ('Settings' in permissions){
      var settingsKeys = Object.keys(permissions.Settings);
      for (var key in settingsKeys) {
        switch(settingsKeys[key]) {
          case "Create" :
          userPermissions.createSettings = permissions.Settings.Create;
          break;
          case "Read" :
          userPermissions.readSettings = permissions.Settings.Read;
          break;
          case "Update" : 
          userPermissions.updateSettings = permissions.Settings.Update;
          break;
          case "Delete" :
          userPermissions.deleteSettings = permissions.Settings.Delete;
          break;
          case "Assign" :
          userPermissions.assignSettings = permissions.Settings.Assign;
          break;
        }
      }
    }
    //For Employees
    if ('Employees' in permissions){
      var employeesKeys = Object.keys(permissions.Employees);
      for (var key in employeesKeys) {
        switch(employeesKeys[key]) {
          case "Create" :
          userPermissions.createEmployees = permissions.Employees.Create;
          break;
          case "Read" :
          userPermissions.readEmployees = permissions.Employees.Read;
          break;
          case "Update" : 
          userPermissions.updateEmployees= permissions.Employees.Update;
          break;
          case "Delete" :
          userPermissions.deleteEmployees = permissions.Employees.Delete;
          break;
          case "Assign" :
          userPermissions.assignEmployees = permissions.Employees.Assign;
          break;
        }
      }
    }
    return userPermissions;
  }

}
    
   