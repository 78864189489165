import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-router.module';
import { MatFormFieldModule } from '@angular/material';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { HttpErrorHandler } from './error-handler.service';
import { LoginService } from './services/index';
//import { InterceptorService, AuthService } from './helpers/index';
import { S3Util, CognitoUtil, StorageUtil } from './utils/index';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AssetsComponent } from './assets/assets.component';
import { HeaderComponent } from './header/header.component';
import {ApiService} from "./core/api.service";
import { RegisterComponent } from './register/register.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { JwttokenDataService } from './jwttoken-data.service';
import { AuthService} from './helpers/auth.service';
import {AuthGuard} from './guards/auth.guard';
import {RoleDirective} from './helpers/roledirective';

//*** */

import {CommonModule} from '@angular/common';

import {RouterModule} from '@angular/router';

import {A11yModule} from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTableModule,
  
  
} from '@angular/material';

import { NavService } from './services/nav.service';
import { UsersComponent } from './users/users.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { UpdateRegisterUserComponent } from './update-register-user/update-register-user.component';
import { AddHardwareAssetsComponent } from './add-hardware-assets/add-hardware-assets.component';
import { ViewHardwareAssetsComponent } from './view-hardware-assets/view-hardware-assets.component';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDialogComponent } from './mat-dialog/mat-dialog.component'
//import { TestComponent } from './test/test.component';


export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    ToolbarComponent,
    NavigationComponent,
    AssetsComponent,
    HeaderComponent,
    RegisterComponent,
    MenuListItemComponent,
    UsersComponent,
    RegisterUserComponent,
    UpdateRegisterUserComponent,
    AddHardwareAssetsComponent,
    ViewHardwareAssetsComponent,
    RoleDirective,
    MatConfirmDialogComponent,
    LoginComponent,
    MatDialogComponent
    //TestComponent,
   
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    AppRoutingModule,
    MatTableModule,
    MatInputModule,
    MatToolbarModule,
    CommonModule,
    
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: []
      }
    }),
  ],
  providers: [
    ApiService,NavService,JwttokenDataService, AuthService, AuthGuard,
    { provide: ErrorHandler },

    HttpErrorHandler,
    { provide: ErrorHandler, useClass: HttpErrorHandler },
   // { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    LoginService,
    S3Util,
    CognitoUtil,
    StorageUtil
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents:[MatConfirmDialogComponent,MatDialogComponent]
})
@NgModule({
  exports: [
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    ScrollDispatchModule,
    CdkStepperModule,
    CdkTableModule,
    
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    CommonModule,
    MatToolbarModule,
    MatInputModule,
    MatTableModule,
    LoginComponent,
  ]
})
export class AppModule { }
