// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  REGION: 'us-east-1',
  ACCESS_KEY: 'AKIAJSJMTIMBMF4W74AA',
  SECRET_ACCESS_KEY: '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',
  IDENTITY_POOL_ID: 'us-east-1:fbe0340f-9ffc-4449-a935-bb6a6661fd53',
  USER_POOL_ID: 'us-east-1_P4xn6XyaY',
  CLIENT_ID: '6rf9qi31b95nphok39dqdu91pu'
};
