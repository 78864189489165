import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { environment } from '../../environments/environment';

@Injectable()
export class CognitoUtil {
    public static REGION = environment.REGION;
    public static ACCESS_KEY = environment.ACCESS_KEY;
    public static SECRET_ACCESS_KEY = environment.SECRET_ACCESS_KEY;


    public static IDENTITY_POOL_ID = environment.IDENTITY_POOL_ID;
    public static USER_POOL_ID = environment.USER_POOL_ID;
    public static CLIENT_ID = environment.CLIENT_ID;

    public static POOL_DATA = {
        UserPoolId: CognitoUtil.USER_POOL_ID,
        ClientId: CognitoUtil.CLIENT_ID
    };

    public static userPool: CognitoUserPool;
    public static cognitoIdentityServiceProvider;

    public cognitoCreds: AWS.CognitoIdentityCredentials;

    // public cognitoService : CognitoService;

    constructor() {
        this.setAWSCreadentials();
        CognitoUtil.userPool = this.getUserPool();
        CognitoUtil.cognitoIdentityServiceProvider = this.getAWSServiceProvider();
    }

    getUserPool(): CognitoUserPool {
        return new AWSCognito.CognitoUserPool(CognitoUtil.POOL_DATA);
    }

    getAWSServiceProvider() {
        return new AWS.CognitoIdentityServiceProvider();
    }

    createAuthenticationCreadentials( authData ) {
        return new AWSCognito.AuthenticationDetails( authData );
    }

    getCognitoUser(userName) {
        const userData = {
            Username: userName,
            Pool: CognitoUtil.userPool
        };

        return new AWSCognito.CognitoUser(userData);
    }

    setUserPoolCredentials(userPool) {
        // let userPool: CognitoUserPool = this.getUserPool();
        userPool['client'].config.region = CognitoUtil.REGION;
        userPool['client'].config.update({
            accessKeyId: CognitoUtil.ACCESS_KEY,
            secretAccessKey: CognitoUtil.SECRET_ACCESS_KEY
        });
    }

    setAWSCreadentials() {
        AWS.config.update({
            region: CognitoUtil.REGION,
            accessKeyId: CognitoUtil.ACCESS_KEY,
            secretAccessKey: CognitoUtil.SECRET_ACCESS_KEY
        });

    }

    getCurrentUser() {
        return CognitoUtil.userPool.getCurrentUser();
    }

    authenticateUser(authCreadential) {
        const promise = new Promise((resolve, reject) => {

            const authenticationDetails = this.createAuthenticationCreadentials( authCreadential );

            const cognitoUser = this.getCognitoUser( authCreadential.Username );

            cognitoUser.authenticateUser( authenticationDetails, {

                onSuccess: function (result) {
                    const token = result.getAccessToken().getJwtToken();
                    resolve(token);
                },
                onFailure: function (error) {
                    localStorage.clear();
                    reject(error);
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {

                    const newPassword = authCreadential.password;
                    const attributesData = { };
                    cognitoUser.completeNewPasswordChallenge(newPassword, attributesData, this);
                }
            });
        });
        return promise;
    }

    getUserList() {

        const promise = new Promise((resolve, reject) => {

            this.setUserPoolCredentials(CognitoUtil.userPool);

            CognitoUtil.userPool['client'].listUsers({

                'UserPoolId': CognitoUtil.USER_POOL_ID
            }, function (data, list) {
                if (list) {
                    resolve(list);
                } else {
                    reject();
                }
            });
        });
        return promise;
    }

    adminCreateUser(params) {

        return new Promise((resolve, reject) => {
            CognitoUtil.cognitoIdentityServiceProvider.adminCreateUser(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

    adminDeleteUser(params) {

        return new Promise((resolve, reject) => {
            CognitoUtil.cognitoIdentityServiceProvider.adminDeleteUser(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

    adminAddUserToGroup(params) {
        return new Promise((resolve, reject) => {
            CognitoUtil.cognitoIdentityServiceProvider.adminAddUserToGroup(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

    adminResetUserPassword(params) {
        return new Promise((resolve, reject) => {
            CognitoUtil.cognitoIdentityServiceProvider.adminResetUserPassword(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

    listGroup(params) {
        return new Promise((resolve, reject) => {
            CognitoUtil.cognitoIdentityServiceProvider.listGroups(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }


    adminInitiateAuth(params) {

        return new Promise((resolve, reject) => {
            CognitoUtil.cognitoIdentityServiceProvider.adminInitiateAuth(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }
}
