import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';


@Injectable()
export class HttpErrorHandler implements ErrorHandler {

    constructor() { }

    /*
    * Returns a function that handles Http operation failures.
    * This error handler lets the app continue to run as if no error occurred.
    */

    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            console.log('ERROR', error);
        } else {
           // Handle Client Error (Angular Error, ReferenceError...)
        }
        console.log('Error', error);
    }
}
