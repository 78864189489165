import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { VERSION } from '@angular/material';

import { NavService } from '../services/nav.service';
import { Router } from '@angular/router';
import { NavItem } from '../model/nav-item';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../core/api.service";

import { LogoutUser } from '../model/logoutuser.model';
import { DialogService } from '../services/dialog.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements AfterViewInit {
  @ViewChild('appDrawer') appDrawer: ElementRef;
  version = VERSION;
  loginForm: FormGroup;
  user: LogoutUser;
  userNameFromStorage = localStorage.getItem('username');
  navItems: NavItem[] = [
    {
      displayName: 'Assets',
      iconName: 'devices_other',
      route: 'assets',

    },

    {
      displayName: 'Administration',
      iconName: 'settings',
      children: [
        {
          displayName: 'Users',
          iconName: 'group',
          route: 'adminstration/users'

        },


      ]
    },

  ];

  constructor(private navService: NavService, private formBuilder: FormBuilder, private router: Router, private dialogService: DialogService, private apiService: ApiService, public dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  onLogout() {


    this.user = new LogoutUser();
    this.user.User = localStorage.getItem('username');

    if (this.user.User) {
      this.apiService.logoutUser(this.user)
        .subscribe(data => {
          console.log(data + "Logged Out Successfully");
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('clientname');

          this.router.navigate(['/']);
          return true;
        },
          error => {
            alert(error);
          }

        );
    } else {
      this.router.navigate(['/assets']);
      return false;
    }




  }

}