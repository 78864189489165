import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ApiService } from "../core/api.service";
import { AssetsDataService } from '../assetsdata.service';
import { DataSource } from '@angular/cdk/collections';
import { AssetsApiResponse } from '../model/assetsapi.response';
import { Observable } from "rxjs/index";
import { AssetSubType } from '../model/assetSubType.model';
import { allowPreviousPlayerStylesMerge } from '@angular/animations/browser/src/util';
import { DialogService } from '../services/dialog.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {

  dataSource: any;
  isLoading = true;
  assets: AssetsApiResponse[];
  displayedColumns = ['assetSubTypeId', 'name', 'serialNo', 'model', 'vendor', 'cost', 'invoiceNo', 'statusId', 'Actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sortForDataSource: MatSort;
  isShowHideButtonVisible = true;
  allocated;
  assetsAssignment = [];
  private assetSubTypes = [];
  private assetSubTypeMap = new Map<string, string>();

  constructor(private router: Router, private assetListService: ApiService, private assetDataService: AssetsDataService, private dialogService: DialogService, private snackBar: MatSnackBar) { }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sortForDataSource;
  }


  ngOnInit() {

    this.isLoading = true;
    this.assetListService.getAllAssetSubTypes()
      .subscribe((data: any[]) => {
        console.log(data);
        this.assetSubTypes = data;

        for (var i = 0; i < this.assetSubTypes.length; i++) {
          var id = this.assetSubTypes[i].id;
          var name = this.assetSubTypes[i].name;
          console.log("id,name......", id, name);
          this.assetSubTypeMap.set(id, name);
        }

        this.assetListService.getAllAssets()
          .subscribe((data: any[]) => {

            this.isLoading = false;
            this.assets = data;
            for (var j = 0; j < this.assets.length; j++) {
              var assetSubType = this.assetSubTypeMap.get(this.assets[j].assetSubTypeId);

              console.log("Replacing asset subtype id " + this.assets[j].assetSubTypeId + " with asset sub type string " + assetSubType);
              this.assets[j].assetSubTypeId = assetSubType;

            }
            this.dataSource = new MatTableDataSource(data);
            this.assets = data;

            console.log("assets==================================", this.assets);
            console.log("assets length", this.assets.length);


            this.assets.forEach(element => {

              this.allocated = this.assets[i].assetAssignments[0].statusId;
            });
            console.log("status......", this.assetsAssignment);

          })
      });

  }



  addNewHardwareAssets() {
    this.router.navigate(['add-hardware-assets']);
  }
  onClickOfBack() {
    this.router.navigate(['assets']);
  }
  onClickViewAssets(asset: AssetsApiResponse): void {
    console.log("On Click of View Assets")
    localStorage.removeItem("editAssetId");
    localStorage.setItem("editAssetId", asset.id.toString());
    this.router.navigate(['view-hardware-assets']);
  }

  onClickDeleteAsset(asset: AssetsApiResponse, index: number): any {
    var id = localStorage.getItem(asset.id.toString());
    var dialogRef = this.dialogService.openDialog("Are you sure you want to delete this Asset!!");

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed result = ' + result);

      if (result) {
        this.assetListService.deleteAsset(asset.id)
          .subscribe(data => {
            this.assets = this.assets.filter(a => a.id !== asset.id);
            this.dataSource.data = this.assets;
            return true;
          })

      } else {
        this.router.navigate(['/assets']);
        return false;
      }

    });

  };

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
export class UserDataSource extends DataSource<any> {

  constructor(private assetListService: ApiService) {
    super();
  }
  connect(): Observable<AssetsApiResponse[]> {

    return this.assetListService.getAllAssets();
  }
  disconnect() { }
}

