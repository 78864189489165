import { Injectable } from '@angular/core';
import {GetAllUsersApiResponse} from './model/getallusersapi.response';


@Injectable({
  providedIn: 'root'
})
export class JwttokenDataService {

  constructor() { }

  getJWTTokenDetails():String{
    const userIdKey = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
    let jwt = localStorage.getItem('token');
    let jwtData = jwt.split('.')[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);

    let isAdmin = decodedJwtData.admin;
    let loggedInUserId = decodedJwtData[userIdKey];

    console.log('jwtData: ' + jwtData);
    console.log('decodedJwtJsonData: ' + decodedJwtJsonData);
    console.log('decodedJwtJsonData-Sub: ' + decodedJwtJsonData.sub);
    console.log('decodedJwtData-userIdKey: ' + loggedInUserId);
    console.log('Is admin: ' + isAdmin);
    return loggedInUserId;
  }

  getUserDetails(){

    var user : GetAllUsersApiResponse;
    localStorage.removeItem("editUserId");
    localStorage.setItem("editUserId",user.id.toString());
    console.log("UserId", user.id.toString());
    return user.id.toString();

  }
}
