import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

import { environment } from '../../environments/environment';

@Injectable()
export class S3Util {
    public static REGION = environment.REGION;
    public static ACCESS_KEY = environment.ACCESS_KEY;
    public static SECRET_ACCESS_KEY = environment.SECRET_ACCESS_KEY;

    public static s3Instance;
    private s3Bucket;


    constructor() {
        this.init();
    }

    init() {
        S3Util.s3Instance = new S3({
            accessKeyId: S3Util.ACCESS_KEY,
            secretAccessKey: S3Util.SECRET_ACCESS_KEY,
            region: S3Util.REGION
        });
    }

    upload(params) {
        return new Promise((resolve, reject) => {
            S3Util.s3Instance.upload(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

    putObject(params) {
        return new Promise((resolve, reject) => {
            S3Util.s3Instance.putObject(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

    getObject(params) {
        return new Promise((resolve, reject) => {
            S3Util.s3Instance.getObject(params, function (error, data) {
                if (error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            });
        });
    }

}
