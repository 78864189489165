import { Injectable } from '@angular/core';

@Injectable()
export class StorageUtil {

  set( key, value ): void {
    value = JSON.stringify( value );
    localStorage.setItem( key, value );
  }

  get( key ): any {
    return JSON.parse( localStorage.getItem(key) );
  }

  clearStorage(): void {
    localStorage.clear();
  }
}
