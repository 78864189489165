import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import { Router } from '@angular/router';
import {NavItem} from '../model/nav-item';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../core/api.service";

import { LogoutUser} from '../model/logoutuser.model';
import { DialogService } from '../services/dialog.service';



@Component({
  selector: 'app-mat-confirm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.css']
})
export class MatConfirmDialogComponent implements OnInit {

  user: LogoutUser;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private router: Router, private apiService: ApiService,
  public dialogRef: MatDialogRef<MatConfirmDialogComponent>){}
  ngOnInit() {
  }

  closeDialog(){  
    this.onLogout2();
    this.dialogRef.close(false);
  }

  
  onLogout2() {
    
  

    this.user = new LogoutUser();
    this.user.User = localStorage.getItem('username');
   
    if(this.user.User ){
      this.apiService.logoutUser(this.user)
      .subscribe(data => {
        // this.dialogService.openConfirmDialog("Are you sure you want to Logout!!");
              console.log(data + "Logged Out Successfully");
              localStorage.removeItem('token');
              localStorage.removeItem('username');
              localStorage.removeItem('clientname');
             // localStorage.removeItem('editAssetId')
              this.router.navigate(['/']);
              return true;
      },
            error => {
              alert(error);
            }

    );
   } else {
      this.router.navigate(['/assets']);
      return false;
    }

    


  }

}
