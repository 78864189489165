import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ApiService } from "../core/api.service";
import { DataSource } from '@angular/cdk/collections';
import { Observable } from "rxjs/index";
import { GetAllUsersApiResponse } from '../model/getallusersapi.response';
import { UserdataService } from '../userdata.service';

@Component({
  selector: 'users.component',
  styleUrls: ['users.component.css'],
  templateUrl: 'users.component.html',
})
export class UsersComponent implements OnInit {
  dataSource: any;
  users: GetAllUsersApiResponse[];
  displayedColumns = ['userName', 'email', 'firstName', 'lastName', 'phoneNumber', 'isOnline', 'isActive', 'Actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private userService: ApiService, private userDataService: UserdataService) { }
  clickMessage = '';
  isLoading = true;

  ngOnInit() {
    this.isLoading = true;
    this.userService.getAllUsers().
      subscribe(
        data => {
          console.log("Users List", data);
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(data);
          this.users = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

        },
        error => this.isLoading = false
      );
  }


  onUserClick() {
    console.log("Register User Button Clicked")
    this.clickMessage = 'I am in Angular App!';
    this.router.navigate(['register-user']);

  }
  onUpdateUser(user: GetAllUsersApiResponse) {
    console.log("Update User Button Clicked");

    this.userDataService.user = user;
    this.router.navigate(['update-register-user']);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    })
  }
}

export class UserDataSource extends DataSource<any> {

  constructor(private userService: ApiService) {
    super();
  }
  connect(): Observable<GetAllUsersApiResponse[]> {

    return this.userService.getAllUsers();
  }
  disconnect() { }
}
export class ProgressSpinnerConfigurableExample {
  color = 'primary';
  mode = 'determinate';
  value = 50;
}

