import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm, ValidatorFn, ValidationErrors } from "@angular/forms";
import { ApiService } from "../core/api.service";
import { AssetSubType } from '../model/assetSubType.model';
import { MIN_LENGTH_VALIDATOR } from '@angular/forms/src/directives/validators';
import { DialogService } from '../services/dialog.service';
import { MatSnackBar } from '@angular/material';
import { SnackBarService } from '/home/user/asset-management/frontend/src/app/services/snack-bar.service'

@Component({
  selector: 'app-add-hardware-assets',
  templateUrl: './add-hardware-assets.component.html',
  styleUrls: ['./add-hardware-assets.component.css']
})
export class AddHardwareAssetsComponent implements OnInit {

  createAssetForm: FormGroup;
  asset_attribute_details = [];
  selectedAssetSubType: string;
  assetSubTypes: AssetSubType[];
  assetSubTypeId = new FormControl('', [Validators.required]);
  isLoading = true;
  minDate = new Date(2016, 0, 1);
  maxDate = new Date(2039, 0, 1);

  constructor(private router: Router, private apiService: ApiService, private dialogService: DialogService, private formBuilder: FormBuilder, private snackBar: MatSnackBar, private snackbar: SnackBarService) { }

  myFilter = (d: Date): boolean => {
    const purchasedDate = this.createAssetForm.get('purchasedDate').value;
    return new Date(d) > new Date(purchasedDate);
  }

  myFilter1 = (d: Date): boolean => {
    const insuredDate = this.createAssetForm.get('insuredDate').value;
    return new Date(d) > new Date(insuredDate);
  }
  ngOnInit() {
    this.createAssetForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      serialNo: ['', Validators.compose([Validators.required])],
      make: ['', Validators.compose([Validators.required])],
      model: ['', Validators.compose([Validators.required])],
      vendor: ['', Validators.compose([Validators.required])],
      cost: ['', Validators.compose([Validators.required])],
      purchasedDate: ['', Validators.compose([Validators.required])],
      warrentyDate: ['', Validators.compose([Validators.required])],
      serviceTag: ['', Validators.compose([Validators.required])],
      invoiceNo: (['', Validators.compose([Validators.required])]),
      insuredDate: ['', Validators.compose([Validators.required])],
      insuredEndDate: ['', Validators.compose([Validators.required])],
      remark: ['', Validators.compose([Validators.required])],
      assetSubTypeId: ['', Validators.compose([Validators.required])],
      assetAttributeDetails: this.asset_attribute_details,
    },

    );

    this.isLoading = true;
    this.apiService.getAllAssetSubTypes()
      .subscribe(data => {
        this.isLoading = false;
        this.assetSubTypes = data;
        console.log("Asset Sub Types", this.assetSubTypes)
      });


  }

  onClickOfBack() {
    this.router.navigate(['assets']);
  }

  onCreateAssetFormSubmit() {
    console.log("Create Asset Form Details", this.createAssetForm.value);
    this.apiService.createNewAsset(this.createAssetForm.value)
      .subscribe(data => {
        console.log("Create Asset Form Data", data);
        this.router.navigate(['assets']);
      });
  }

  openSnackBar() {
    this.snackbar.openSnackBar("Added successfully");
  }

}
