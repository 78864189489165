import { Component, OnInit, Inject } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../core/api.service";
import { AuthService } from "../helpers/auth.service";
import { DialogService } from '../services/dialog.service';
import {MatSnackBar} from '@angular/material';
import { SnackBarService } from '/home/user/asset-management/frontend/src/app/services/snack-bar.service'


//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  invalidLogin: boolean = false;
  passwordType: string = 'password';
  passwordShown: boolean = false;
  
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private authservice: AuthService, private dialogService: DialogService, private snackBar: MatSnackBar,  private snackbar: SnackBarService) { }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    const loginPayload = {
      Client: this.loginForm.controls.clientname.value,
      User: this.loginForm.controls.username.value,
      Password: this.loginForm.controls.password.value
    }
    this.apiService.login(loginPayload)
      .subscribe(data => {
      
          if(data.isJWTtokenCreated === true) {
              console.log(data.jwTtoken);
              localStorage.setItem('token', data.jwTtoken);
              localStorage.setItem('username', this.loginForm.controls.username.value);
              localStorage.setItem('clientname', this.loginForm.controls.clientname.value)
            //  var result = this.dialogService.openConfirmDialog("Thank you!" + "You are loggedin Successfully");
            //  var result = confirm("Thank you!" + "You are loggedin Successfully");
              if (loginPayload) {
           
                
                this.router.navigate(['assets']);
                return true;
              }else {
                this.router.navigate(['/']);
                return false;
              }
              
              
              console.log(data.statusMessage)
              var getToken = localStorage.getItem('token');
              console.log(getToken);
             let value1 = "Bearer" + getToken;
              
        
          }else {
              //this.invalidLogin = true;
              //alert(data.statusMessage + ' ' + 'Please enter valid credentials');
             // alert("Invalid Username or Passwaord, Please try again!");  
            this.dialogService.openConfirmDialog("Invalid Username or Passwaord, Please try again!");

              console.log("Login  Not Successfull")
          }

      },
            error => {
             // alert("Please Enter Valid Login Credentials!");
             // this.dialogService.openConfirmDialog("Please Enter Valid Login Credentials!");
             // this.error = error

             this.snackbar.openSnackBar("Please Enter Valid Login Credentials!");
            }

    );
  }

ngOnInit() {

    if (this.authservice.isAuthenticated()) {
      this.router.navigate(['assets']);
      return;
    }
    window.localStorage.removeItem('token');
    this.loginForm = this.formBuilder.group({
      clientname: ['Numino', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      username: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')])],
      //password: ['', Validators.required, Validators.minLength(6)],
      password: ['', Validators.required],
    });
    this.loginForm.controls['clientname'].disable();
  }
/*
  openSignInConfirmDialog(){
    console.log("on click of dialog button");
    this.dialogService.openConfirmDialog("Thank you!" + "You are loggedin Successfully").afterClosed().subscribe(res => {
      console.log(res);
      if(res){
        
        this.snackBar.open("Logged in successfully", " ", {
          duration: 1000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: 'blue-snackbar',
          
        });
        this.router.navigate(['assets']);
        
        return true;
      }else{
        this.router.navigate(['/']);
        return false;
      }
    });
  }

  openSignInSnackBar(message: string, action: string) {
    console.log("open snackbar");
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }*/

storeLoginCredentials(){
    var user = localStorage.setItem('username', this.loginForm.controls.username.value);
    console.log(user);
    var client = localStorage.setItem('clientname', this.loginForm.controls.clientname.value)
    console.log(client);
}

checkStoredLoginCredentials(){

    var extractUsernameFromStorage = localStorage.getItem('username');
    var extractClientnameFromStorage = localStorage.getItem('clientname');

    var extractUsernameFromLoginForm =  this.loginForm.controls.username.value;
    var extractClientnameFromLoginForm = this.loginForm.controls.clientname.value;

    if(extractUsernameFromLoginForm == extractUsernameFromStorage && extractUsernameFromLoginForm == extractClientnameFromStorage) {
      alert('You are already logged in');
    }else {
      alert('ERROR');
    }
}
toggelepassword(){
  if(this.passwordShown){
    this.passwordShown = false;
    this.passwordType = 'password';
  }else {
    this.passwordShown = true;
    this.passwordType = 'text';
  }
}

}


