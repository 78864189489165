import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, CanActivate } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { AssetsComponent } from './assets/assets.component';
import { RegisterComponent } from './register/register.component';
import { UsersComponent } from './users/users.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { UpdateRegisterUserComponent } from './update-register-user/update-register-user.component';
import { AddHardwareAssetsComponent } from './add-hardware-assets/add-hardware-assets.component';
import { ViewHardwareAssetsComponent } from './view-hardware-assets/view-hardware-assets.component';
import {AuthGuard} from './guards/auth.guard';
//import { TestComponent } from './test/test.component';

/*
{path: '', component: LoginComponent, pathMatch: 'full'},
{ path: 'login', component: LoginComponent,
            children: [
                {path: 'users', component: UsersComponent},
                
                //{path: 'clients', component: MyProfileComponent},
               // {path: 'settings', component: AssetsComponent},
            ]

*/

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent}, 
  { path: '', component: HomeLayoutComponent,
    children: [
      { path: '', redirectTo: 'assets', pathMatch: 'full' },
      { path: 'assets', component: AssetsComponent, canActivate: [AuthGuard] },
            /*children:  [
              {path:'assets-all', component: AssetsAllComponent},
                  children: [
                    {path:'desktops', component: MyProfileComponent},
                    {path:'laptops', component: AssetsComponent},
                    {path:'mobiles', component: SettingsComponent},
                    {path:'monitors', component: MyProfileComponent},
                    {path:'printers', component: AssetsComponent},
                    {path:'workstations', component: SettingsComponent},
                    {path:'peripherals', component: MyProfileComponent},
                    {path:'miscelenious', component: MyProfileComponent},
                  ]
                

            ]*/
          
     
      { path: 'adminstration', 
            children: [
                {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
                
                //{path: 'clients', component: MyProfileComponent},
               // {path: 'settings', component: AssetsComponent},
            ]
       },
      {path: 'register-user', component: RegisterUserComponent, canActivate: [AuthGuard]},
      {path: 'update-register-user',component: UpdateRegisterUserComponent, canActivate: [AuthGuard]},
      {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
      {path : 'assets', component: AssetsComponent, canActivate: [AuthGuard]},  
      {path: 'add-hardware-assets', component: AddHardwareAssetsComponent, canActivate: [AuthGuard]},
      {path: 'view-hardware-assets', component: ViewHardwareAssetsComponent, canActivate: [AuthGuard]},
      //{path: 'test', component: TestComponent},
       {path: 'logout', redirectTo: '/', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }

