import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material';
import { MatConfirmDialogComponent } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDialogComponent } from '../mat-dialog/mat-dialog.component';
import { identifierModuleUrl } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openConfirmDialog(msg){
    return this.dialog.open(MatConfirmDialogComponent, 
      {width:'390px', 
      disableClose: true, 
      position: { top : "15px"},
      data : 
          {message: msg
      }
    });
  }

   openDialog(msg){
    return this.dialog.open(MatDialogComponent, 
      {width:'400px', 
      disableClose: true, 
      position: { top : "15px"},
      data : 
          {message: msg,           
      }
    });
  } 

  
}
